<template>
  <section :id="data?.blockId ?? null" :data-index="index" :data-block="data.__component" class="container mx-auto mt-20 lg:mt-28">
    <div class="md:max-w-md">
      <h1 class="text-3xl md:text-5xl font-medium tracking-tighter text-balance"> {{ data.title }} </h1>
      <div v-if="data.lead" class="prose prose-sm md:prose-base mt-4 md:mt-8">
        <MarkdownRenderer :markdown="data.lead" />
      </div>
    </div>
    <div class="mt-4 flex max-md:flex-col gap-2 md:items-center">
      <Button
          v-for="button in data.buttons"
          :title="button.title"
          :link="button.href"
          :variant="button.type"
          :size="button.size"
          :arrow="button.arrow"
      />
    </div>
    <div class="mt-6 md:mt-10 grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-x-6 gap-y-8">
      <div v-for="employee in filteredEmployees">
        <div class="w-full h-[200px] sm:h-[300px] md:h-[360px] xl:h-[480px] relative rounded-lg overflow-hidden bg-black">
          <NuxtPicture v-if="employee.attributes.image.data?.attributes.hash"
                       sizes="50vw sm:33vw lg:300px"
                       :height="employee.attributes.image.data?.attributes.height"
                       :width="employee.attributes.image.data?.attributes.width"
                       loading="lazy"
                       :src="employee.attributes.image.data?.attributes.hash"
                       :alt="employee.attributes.image.data?.attributes.alternativeText || ''"
                       :imgAttrs="{
                              class:'absolute inset-0 w-full h-full object-cover object-top rounded-kg',
                              
                           }"
          />
          <div v-if="!employee.attributes.image.data" class="absolute inset-0 w-full h-full flex items-center justify-center">
            <div class="w-10 h-10 lg:w-16 lg:h-16">
              <svg class="w-full h-full" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.7619 31.9999C16.7619 41.2572 9.25732 48.7618 0 48.7618L6.66077e-07 63.9998C17.6563 63.9998 31.9727 49.7002 31.9999 32.0503C32.027 49.7003 46.3434 64 63.9998 64L63.9998 48.7619C54.7424 48.7619 47.2379 41.2574 47.2379 32.0001L16.7619 31.9999Z" fill="white"/>
                <path d="M48.7618 32C48.7618 22.7427 41.2572 15.2382 31.9999 15.2382C22.7426 15.2382 15.2381 22.7426 15.2381 31.9999L6.97341e-05 31.9999C6.89616e-05 14.3269 14.3269 7.72514e-07 32 0C49.673 3.50524e-05 63.9998 14.327 63.9998 32H48.7618Z" fill="white"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <h2 class="font-medium max-md:text-sm text-balance">{{ employee.attributes.name }}</h2>
          <p class="text-black/50 max-md:text-xs">{{ employee.attributes.workTitle }}</p>
        </div>
        <div class="mt-3 flex items-center gap-6">
          <a class="leading-tight border-b border-b-black" :href="'mailto:' + employee.attributes.email" v-if="employee.attributes.email">Email</a>
          <a class="leading-tight border-b border-b-black" target="_blank" :href="employee.attributes.linkedIn" v-if="employee.attributes.linkedIn">LinkedIn</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import MarkdownRenderer from '../components/MarkDown.vue';
import {ref, computed} from "vue";
import Button from "../components/Button.vue";

const active = ref(0);
const props = defineProps<{
  data: any,
  index: any
}>();

const filteredEmployees = computed(() => {
  return props.data.employees.data.filter((employee: any) => !!employee.attributes.publishedAt);
})
</script>