<template>
  <section :id="data?.blockId ?? null" :data-index="index" :data-block="data.__component" v-if="data.Style === 'Simple'"
    class="container mx-auto max-w-5xl mt-20 md:mt-32">
    <div class="flex flex-col items-center gap-9 md:gap-12">
      <div class="max-md:max-w-[256px] text-center text-base md:text-xl  grid gap-2">
        <h2 class="font-medium text-balance">{{ data.Title }}</h2>
        <div class="text-black/50" v-if="data.Lead">
          <MarkdownRenderer :markdown="data.Lead" />
        </div>
      </div>
      <ul class="grid grid-cols-3 gap-6 md:gap-14 lg:grid-cols-6">
        <li class="h-16 lg:h-24 mx-auto" v-for="company in filteredCompanies">
          <NuxtPicture v-if="company.attributes.logo.data?.attributes.hash"
            :width="company.attributes.logo.data?.attributes.width"
            :height="company.attributes.logo.data?.attributes.height" loading="lazy"
            :src="company.attributes.logo.data?.attributes.hash"
            :alt="company.attributes.logo.data?.attributes.alternativeText || ''" :imgAttrs="{
    class: 'h-full w-full object-contain',

  }" />
        </li>
      </ul>
    </div>
  </section>

  <section v-if="data.Style !== 'Simple'" class="container mx-auto mt-14 md:mt-28 relative">
    <div class="max-md:hidden absolute -top-[116px] -right-10">
      <svg xmlns="http://www.w3.org/2000/svg" width="200" height="160" viewBox="0 0 200 160" fill="none">
        <path d="M30.4762 80.0002C30.4762 63.1686 16.8315 49.524 0 49.524" stroke="#070A26" stroke-width="19.0476" />
        <path d="M129.524 -9.23097e-05C129.524 16.8315 143.168 30.4761 160 30.4761" stroke="#070A26"
          stroke-width="19.0476" />
        <path d="M40.0004 70.4762C56.8319 70.4762 70.4766 56.8316 70.4766 40" stroke="#070A26" stroke-width="19.0476" />
        <path d="M89.5238 80.0005C89.5238 96.832 103.168 110.477 120 110.477" stroke="#070A26" stroke-width="19.0476" />
        <path d="M200 129.524C183.168 129.524 169.523 143.168 169.523 160" stroke="#070A26" stroke-width="19.0476" />
      </svg>
    </div>
    <div class="relative">
      <div class="max-w-2xl grid gap-6 md:gap-8 ">
        <h2 class="text-3xl md:text-5xl font-medium text-balance">{{ data.Title }}</h2>
        <div class="prose max-w-md" v-if="data.Lead">
          <MarkdownRenderer :markdown="data.Lead" />
        </div>
      </div>
      <ul class="mt-4 md:mt-8 grid grid-cols-2 md:grid-cols-3 gap-3 lg:gap-6">
        <li v-for="(company, index) in filteredCompanies" class="h-[90px] lg:h-[130px]">
          <div class="bg-light-grey flex items-center justify-center h-full w-full">
            <NuxtPicture v-if="company.attributes.logo.data?.attributes.hash"
              :width="company.attributes.logo.data?.attributes.width"
              :height="company.attributes.logo.data?.attributes.height" loading="lazy"
              :src="company.attributes.logo.data?.attributes.hash"
              :alt="company.attributes.logo.data?.attributes.alternativeText || ''" :imgAttrs="{
    class: 'h-[70px] lg:h-[90px] w-auto',

  }" />
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup lang="ts">
import MarkdownRenderer from '../components/MarkDown.vue';
import { computed } from 'vue';
const props = defineProps<{
  data: any,
  index: any,
}>();

const filteredCompanies = computed(() => {
  if(!props.data?.companies?.data) return [];

  return props.data?.companies?.data.reduce((acc, item) => {
  if (!acc.some(existingItem => existingItem.documentId === item.documentId)) {
    acc.push(item);
  }
  return acc;
}, [])
});
</script>