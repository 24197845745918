<template>
  <section :id="data?.blockId ?? null" :data-index="index" :data-block="data.__component"
    class="container mx-auto mt-32">
    <h2 class="mb-8 text-2xl md:text-3.5xl font-medium text-balance" :class="{ 'sr-only': !data.title }">{{ data?.title
    ||
    "Client Testimonials" }}</h2>
    <div class="rounded-2xl relative overflow-hidden" v-if="data?.testimonials?.data?.length > 0" :class="{
    'bg-[#CEFFC6]/20': data?.testimonials?.data[activeSlide]?.attributes?.company?.data?.attributes.color === 'green',
    'bg-[#26DDFD]/10': data?.testimonials?.data[activeSlide]?.attributes?.company?.data?.attributes.color === 'blue',
    'bg-[#FF5C00]/10': data?.testimonials?.data[activeSlide]?.attributes?.company?.data?.attributes.color === 'orange'
  }">
      <div class="relative z-10">
        <Splide :options="options" @splide:moved="onMove" @splide:mounted="move" ref="splideRef">
          <SplideSlide v-for="(testimonial, index) in filteredTestimonials">
            <div class="px-8 py-14 sm:p-16">
              <div class="relative">
                <div class="md:absolute top-0 right-0 h-20 w-20 md:w-[150px] md:h-auto">
                  <NuxtPicture v-if="testimonial?.attributes?.company?.data?.attributes?.icon.data?.attributes?.url"
                    :width="testimonial.attributes.company.data?.attributes.icon.data?.attributes.width"
                    :height="testimonial.attributes.company.data?.attributes.icon.data?.attributes.height"
                    loading="lazy" sizes="56px sm:130px"
                    :src="testimonial.attributes.company.data?.attributes.icon.data?.attributes.hash"
                    :alt="testimonial.attributes.company.data?.attributes.icon.data?.attributes.alternativeText || ''"
                    :imgAttrs="{
    class: 'w-full h-auto'
  }" />
                </div>
                <div class="max-md:pt-12 max-w-[520px]">
                  <div v-if="testimonial?.attributes?.testimonial" class="prose md:prose-lg " :class="{
    'text-[#375834]': testimonial?.attributes?.company?.data?.attributes?.color === 'green',
    'text-[#0F4853]': testimonial?.attributes?.company?.data?.attributes?.color === 'blue',
    'text-[#4E1F05]': testimonial?.attributes?.company?.data?.attributes?.color === 'orange'
  }">
                    <MarkdownRenderer :markdown="testimonial?.attributes?.testimonial" />
                  </div>
                  <div class="mt-6 md:mt-24 flex items-center gap-4">
                    <div v-if="testimonial.attributes.image.data?.attributes.hash"
                      class="h-10 w-10 rounded-full relative overflow-hidden">
                      <NuxtPicture v-if="testimonial.attributes.image.data?.attributes.hash" loading="lazy"
                        :height="testimonial.attributes.image.data?.attributes.height"
                        :width="testimonial.attributes.image.data?.attributes.width" sizes="40px"
                        :src="testimonial.attributes.image.data?.attributes.hash"
                        :alt="testimonial.attributes.image.data?.attributes.alternativeText || ''" :imgAttrs="{
    class: 'absolute inset-0 h-full w-full object-cover'
  }" />
                    </div>
                    <div>
                      <p class="text-sm font-medium" :class="{
    'text-[#375834]': testimonial.attributes.company?.data?.attributes.color === 'green',
    'text-[#0F4853]': testimonial.attributes.company?.data?.attributes.color === 'blue',
    'text-[#4E1F05]': testimonial.attributes.company?.data?.attributes.color === 'orange'
  }"> {{ testimonial.attributes.name }}</p>
                      <p class="text-sm" :class="{
    'text-[#375834]/80': testimonial.attributes.company?.data?.attributes.color === 'green',
    'text-[#0F4853]/80': testimonial.attributes.company?.data?.attributes.color === 'blue',
    'text-[#4E1F05]/70': testimonial.attributes.company?.data?.attributes.color === 'orange'
  }"> {{ testimonial.attributes.workTitle }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
        </Splide>
        <div v-if="data?.testimonials?.data?.length > 1" class="w-full">
          <div :id="id" class="w-0 h-[2px] bg-black"></div>
        </div>
        <div class="grid border-t border-black/10 divide-x divide-black/10"
          :class="'grid-cols-' + filteredTestimonials.length" v-if="data?.testimonials?.data?.length > 1">
          <button :aria-label="'slide' + index" type="button" @click="() => goToSlide(index)"
            v-for="(testimonial, index) in filteredTestimonials" :key="`slide-button-${index}`"
            class="px-5 py-2 md:px-9 md:py-3 flex items-center justify-center">
            <div class="h-[50px] lg:h-[90px] w-auto transition-opacity duration-500"
              :class="index === activeSlide ? '' : 'opacity-30'">
              <NuxtPicture v-if="testimonial.attributes.company.data?.attributes.logo.data?.attributes.hash"
                :width="testimonial.attributes.company.data?.attributes.logo.data?.attributes.width"
                :height="testimonial.attributes.company.data?.attributes.logo.data?.attributes.height" loading="lazy"
                sizes="50px lg:90px" :src="testimonial.attributes.company.data?.attributes.logo.data?.attributes.hash"
                :alt="testimonial.attributes.company.data?.attributes.logo.data?.attributes.alternativeText || ''"
                :imgAttrs="{
    class: 'h-[50px] lg:h-[90px] w-auto object-contain pointer-events-none select-none'
  }" />
            </div>
          </button>
        </div>
      </div>
      <div class="absolute top-0 right-0 z-0">



        <NuxtPicture width="972" height="578" loading="lazy" alt=""
          :src="`/svg/testimonialBg-${data?.testimonials?.data[activeSlide].attributes.company?.data?.attributes.color || 'orange'}.svg`"
          :imgAttrs="{
    class: 'pointer-events-none select-none',
  }" />


      </div>
    </div>
    <NuxtLink v-if="data?.link?.href" :to="data?.link?.href" class="mt-8 flex items-center justify-center gap-1">
      <p class="font-medium">{{ data?.link?.title }}</p>
      <Icon :name="'ArrowRight'" />
    </NuxtLink>
  </section>
</template>


<script setup lang="ts">
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { onMounted, ref, computed } from 'vue';
import '@splidejs/vue-splide/css';
import MarkdownRenderer from '../components/MarkDown.vue';
import Icon from "../components/Icon.vue";

const props = defineProps<{
  data: any,
  index: number,
}>();

const splideRef = ref(null);

const filteredTestimonials = computed(() => {
  if(!props.data?.testimonials?.data) return [];

  return props.data?.testimonials?.data.reduce((acc, item) => {
  if (!acc.some(existingItem => existingItem.documentId === item.documentId)) {
    acc.push(item);
  }
  return acc;
}, [])
});

defineExpose({
  splideRef
})

const options = {
  type: 'fade',
  role: 'region',
  rewind: true,
  arrows: false,
  pagination: false,
};

const activeSlide = ref(0);
const id = computed(() => `progress-employees-${props.index}`);

// Dynamic grid class computation
const dynamicGridClass = computed(() => {
  const length = props.data.employees.data?.length || 0;
  const clampedLength = Math.max(1, Math.min(length, 5)); // Ensures length is between 1 and 5

  return {
    'grid-cols-1': clampedLength === 1,
    'grid-cols-2': clampedLength === 2,
    'grid-cols-3': clampedLength === 3,
    'grid-cols-4': clampedLength === 4,
    'grid-cols-5': clampedLength === 5,
  };
});

// Methods
function onMove(splide, nextIndex) {
  activeSlide.value = nextIndex;
  move();
}

function goToSlide(index) {
  if (splideRef.value) {
    splideRef.value.go(index);
  }
}

function move() {
  let width = 0;
  const elem = document.getElementById(id.value);

  if (!elem) return;

  function animate() {
    if (width >= 100) {
      splideRef.value?.go('>');
      return;
    }

    width += 0.05; // Adjust the increment as needed
    elem.style.width = `${width}%`;

    requestAnimationFrame(animate);
  }

  animate();
}

onMounted(() => {
  // Any initialization work can go here
});
</script>
