<template>
  <section class="overflow-x-hidden relative">
    <div class="absolute z-0 w-[200%] md:w-full max-md:-left-1/2 -top-96 md:-top-52 flex items-center justify-center">
      <NuxtPicture 
            width="1146" height="1062"
            loading="eager"
            alt=""
            :src="`/svg/heroFrontpageBg.svg`"
            :imgAttrs="{
              class:'pointer-events-none select-none',
            }"
        />
    </div>
    <div class="max-md:hidden absolute left-0 top-[165px]">
      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
        <path d="M30.4762 40C30.4762 23.1684 16.8316 9.52377 0 9.52377" stroke="#070A26" stroke-width="19.0476"/>
        <path d="M80 49.5238C63.1684 49.5238 49.5238 63.1685 49.5238 80" stroke="#070A26" stroke-width="19.0476"/>
      </svg>
    </div>
    <div class="max-md:hidden absolute right-0 top-[205px]">
      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="120" viewBox="0 0 80 120" fill="none">
        <path d="M30.4762 40C30.4762 23.1684 16.8316 9.52376 0 9.52376" stroke="#070A26" stroke-width="19.0476"/>
        <path d="M6.92397e-05 110.476C16.8316 110.476 30.4763 96.8315 30.4763 80" stroke="#070A26" stroke-width="19.0476"/>
        <path d="M79.9999 49.5238C63.1684 49.5238 49.5237 63.1685 49.5237 80" stroke="#070A26" stroke-width="19.0476"/>
      </svg>
    </div>
    <div class="max-md:px-4 mt-14 md:mt-32 md:container md:mx-auto relative z-10">
      <div class="max-w-5xl mx-auto">
        <h1 class="text-3xl md:text-6xl font-medium text-center tracking-tighter text-balance"> {{ data.title }} </h1>
      </div>
      <div v-if="data.lead" class="prose leading-tight  mt-6 md:mt-10 md:max-w-lg md:mx-auto text-center">
        <MarkdownRenderer :markdown="data.lead" />
      </div>
      <div class="mt-8 flex max-md:flex-col gap-2 md:items-center md:justify-center">
        <Button
            v-for="button in data.buttons"
            :title="button.title"
            :link="button.href"
            :variant="button.type"
            :size="button.size"
            :arrow="button.arrow"
        />
      </div>
      <div class="max-md:hidden mt-10 h-[1px] bg-gradient-to-r from-transparent via-[#EEF1F2] to-transparent"></div>
      <div v-if="data.certifications" class="mt-12 md:flex md:items-center md:justify-center md:gap-6">
        <p class="text-sm text-dark-gray text-center" v-if="data.certificationsLabel">{{ data.certificationsLabel }}</p>
        <div class="max-md:w-full flex items-center justify-center gap-4 max-md:mt-3">
          <div v-for="certificate in certificates.data">
            <div class="h-[56px] w-[56px] relative">
              <NuxtPicture v-if="certificate?.attributes.logo.data.attributes.hash"
                           :width="certificate?.attributes.logo.data.attributes.width"
                           :height="certificate?.attributes.logo.data.attributes.height"
                           loading="eager"
                           sizes="56px"
                           :alt="certificate?.attributes.logo.data.attributes.alternativeText || ''"
                           :src="certificate?.attributes.logo.data.attributes.hash"
                           :imgAttrs="{
                              class:'w-auto h-full'
                           }"
              />
              <NuxtLink v-if="certificate?.attributes.link" class="absolute inset-0 h-full w-full" :to="certificate?.attributes.link"/>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 md:mt-[35px] w-full">
        <NuxtPicture v-if="data.image.data?.attributes.hash"
                     
                     preload
                     loading="eager"    
                     :width="data.image.data?.attributes.width"
                     :height="data.image.data?.attributes.height"
                     sizes="90vw sm:1200px"
                     :src="data.image.data?.attributes.hash"
                     :alt="data.image.data?.attributes.alternativeText || ''"
                     :imgAttrs="{
                          class:'w-full drop-shadow-lg rounded-sm',
                       }"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
  import MarkdownRenderer from '../components/components/MarkDown.vue';
  import Button from "../components/components/Button.vue";
  import {useFetch} from "nuxt/app";
  

  const runtimeConfig = useRuntimeConfig();
  const { data: certificates, pending } = useFetch(runtimeConfig.public.STRAPI_API_URL + '/certificates?pLevel', {
	 headers: {
      "Strapi-Response-Format": "v4",
    }
});

  const props = defineProps<{
    data: any
  }>();


  import { useHead } from 'nuxt/app';
 // Set up the head using useHead
useHead({
  link: [
    {
      rel: 'preload',
      as: 'image',
      href: '/svg/heroFrontpageBg.svg', // Adjust the path to your image
    },
  ],
});

</script>