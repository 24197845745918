<template>
  <section :id="data?.blockId ?? null" :data-index="index" :data-block="data.__component" class="container mx-auto mt-32">
    <h2 class="mb-8 text-2xl md:text-3.5xl font-medium text-balance" :class="{'sr-only': !data.title}">{{ data?.title || "Employee Testimonials" }}</h2>
    <div class="rounded-2xl relative overflow-hidden" v-if="data.employees.data.length > 0"
         :class="{
            'bg-[#CEFFC6]/20': data.colorScheme === 'Green',
            'bg-[#26DDFD]/10': data.colorScheme === 'Blue',
            'bg-[#FF5C00]/10': data.colorScheme === 'Orange'
         }">
      <div class="relative z-10">
        <Splide :options="options" @splide:moved="onMove" @splide:mounted="move" ref="splideRef">
          <SplideSlide v-for="testimonial in filteredTestimonials">
            <div class="px-8 py-14 sm:p-16">
              <div class="relative">
                <div class="max-w-[520px]">
                  <div class="prose md:prose-lg "
                       :class="{
                          'text-[#375834]': data.colorScheme === 'Green',
                          'text-[#0F4853]': data.colorScheme === 'Blue',
                          'text-[#4E1F05]': data.colorScheme === 'Orange'
                       }">
                    <MarkdownRenderer v-if="testimonial.attributes.testimonial" :markdown="testimonial.attributes.testimonial" />
                  </div>
                  <div>
                    <p class="text-sm font-medium"
                       :class="{
                          'text-[#375834]': data.colorScheme === 'Green',
                          'text-[#0F4853]': data.colorScheme === 'Blue',
                          'text-[#4E1F05]': data.colorScheme === 'Orange'
                       }"> {{ testimonial.attributes.name }}</p>
                    <p class="text-sm"
                       :class="{
                          'text-[#375834]/80': data.colorScheme === 'Green',
                          'text-[#0F4853]/80': data.colorScheme === 'Blue',
                          'text-[#4E1F05]/70': data.colorScheme === 'Orange'
                       }"> {{ testimonial.attributes.workTitle }}</p>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
        </Splide>
        <div class="w-full" v-if="data.employees.data?.length > 1">
          <div :id="id" class="w-0 h-[2px] bg-black"></div>
        </div>
        <div class="grid border-t border-[#E7F4E6] divide-x divide-[#E7F4E6]" v-if="data.employees.data?.length > 1"
        :class="dynamicGridClass"
        >
          <button :aria-label="'slide'+index"  type="button" v-for="(testimonial, index) in filteredTestimonials" class="p-6 md:p-9 flex items-center justify-center" :key="`slide-button-${index}`" @click="() => goToSlide(index)">
            <div class="flex max-md:flex-col items-center gap-4 transition-all duration-500" :class="index === activeSlide ? '' : 'opacity-30'">
              
              <div class="w-10 h-10 rounded-full relative overflow-hidden bg-black">
                <NuxtPicture v-if="testimonial.attributes.thumbnail.data?.attributes.hash"
                             
                             loading="lazy"
                             :src="testimonial.attributes.thumbnail.data?.attributes.hash"
                             :alt="testimonial.attributes.thumbnail.data?.attributes.alternativeText || ''"
                             :imgAttrs="{
                        class:'absolute inset-0 h-full w-full object-cover'
                     }"
                />
                <div v-if="!testimonial.attributes.thumbnail.data?.attributes.hash" class="absolute inset-0 w-full h-full flex items-center justify-center">
                  <svg class="w-5 h-5" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.7619 31.9999C16.7619 41.2572 9.25732 48.7618 0 48.7618L6.66077e-07 63.9998C17.6563 63.9998 31.9727 49.7002 31.9999 32.0503C32.027 49.7003 46.3434 64 63.9998 64L63.9998 48.7619C54.7424 48.7619 47.2379 41.2574 47.2379 32.0001L16.7619 31.9999Z" fill="white"/>
                    <path d="M48.7618 32C48.7618 22.7427 41.2572 15.2382 31.9999 15.2382C22.7426 15.2382 15.2381 22.7426 15.2381 31.9999L6.97341e-05 31.9999C6.89616e-05 14.3269 14.3269 7.72514e-07 32 0C49.673 3.50524e-05 63.9998 14.327 63.9998 32H48.7618Z" fill="white"/>
                  </svg>
              </div>

              </div>
              <div class="font-medium text-sm">
                {{ testimonial.attributes.name }}
              </div>
            </div>
          </button>
        </div>
      </div>
      <div class="absolute top-0 right-0 z-0">
        
        

        <NuxtPicture
          width="931" height="578"
                       loading="lazy" 
                       alt=""
                       :src="`/svg/testimonialEmployeesBg-${data?.colorScheme || 'orange'}.svg`"
                       :imgAttrs="{
              class:'pointer-events-none select-none',
            }"
          />

      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { onMounted, ref, computed } from 'vue';
import '@splidejs/vue-splide/css';
import MarkdownRenderer from '../components/MarkDown.vue';
import Icon from "../components/Icon.vue";

const props = defineProps<{
  data: any,
  index: number,
}>();

const splideRef = ref(null);

defineExpose({
  splideRef
})

const options = {
  type: 'fade',
  role: 'region',
  rewind: true,
  arrows: false,
  pagination: false,
};

const activeSlide = ref(0);
const id = computed(() => `progress-employees-${props.index}`);

// Dynamic grid class computation
const dynamicGridClass = computed(() => {
    const length = filteredTestimonials.value.length || 0;
    const clampedLength = Math.max(1, Math.min(length, 5)); // Ensures length is between 1 and 5

    return {
        'grid-cols-1': clampedLength === 1,
        'grid-cols-2': clampedLength === 2,
        'grid-cols-3': clampedLength === 3,
        'grid-cols-4': clampedLength === 4,
        'grid-cols-5': clampedLength === 5,
    };
});

// Methods
function onMove(splide, nextIndex) {
  activeSlide.value = nextIndex;
  move();
}

function goToSlide(index) {
  if (splideRef.value) {
    splideRef.value.go(index);
  }
}

function move() {
  let width = 0;
  const elem = document.getElementById(id.value);

  if (!elem) return;

  function animate() {
    if (width >= 100) {
      splideRef.value?.go('>');
      return;
    }

    width += 0.05; // Adjust the increment as needed
    elem.style.width = `${width}%`;

    requestAnimationFrame(animate);
  }

  animate();
}

onMounted(() => {
  // Any initialization work can go here
});

const filteredTestimonials = computed(() => {
  return props.data.employees.data.filter((employee: any) => !!employee.attributes.publishedAt);
})
</script>